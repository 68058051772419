import React from "react";
import { Helmet } from "react-helmet";
import {
  isThisWeek,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  getYear,
  isBefore,
  isAfter,
} from "date-fns";
import banner from "../schedulebanner5.png";

//Checks for BST
// var isBst = (date) => {
//   let year = getYear(new Date());
//   let dateMarch = new Date(year, 3, 1, 12);
//   let dateOctober = new Date(year, 10, 1, 12);
//   return isAfter(date, dateMarch) && isBefore(date, dateOctober);
// };

//Main Component for exporting
const eventHtml = (game, start, end, title, id) => (
  <div
    className="text-milldgrey py-lg-5 py-2 my-2 text-center bg-millyell rounded shadow-sm"
    key={id}
  >
    <p className="my-0 font-weight-bolder" style={{ fontSize: "1.8rem" }}>
      {game}
    </p>
    <p className="mb-1">{title}</p>
    <p>Start: {start}</p>
    {end === "" ? null : <p>End: {end}</p>}
  </div>
);

const SwitchCase = (props) => {
  switch (props.day) {
    case "Monday":
      return isMonday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
    case "Tuesday":
      return isTuesday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
    case "Wednesday":
      return isWednesday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
    case "Thursday":
      return isThursday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
    case "Friday":
      return isFriday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
    case "Saturday":
      return isSaturday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
    case "Sunday":
      return isSunday(new Date(props.date)) &&
        isThisWeek(new Date(props.date), { weekStartsOn: 1 })
        ? eventHtml(
            props.data.game,
            props.data.start.slice(0, 5),
            props.data.end === null ? "" : props.data.end.slice(0, 5),
            props.data.description,
            props.data.id
          )
        : null;
  }
};

const Schedule = (props) => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div className="container-lg schedule-container">
      <Helmet>
        <title>Millbee Schedule</title>
      </Helmet>
      <img
        src={banner}
        className="img-fluid mt-4 mx-auto d-block"
        alt="Schedule banner"
      />

      <p className="text-millyell text-center lead">
        All times in {props.isBst(new Date()) ? "BST" : "GMT"}
      </p>
      <div className="row p-lg-5 rounded flex-lg-nowrap">
        {daysOfWeek.map((day) => (
          <div className="col-lg">
            <p className="text-millyell bg-millgrey  lead  text-center p-2">
              {day}
            </p>
            {props.schedule
              .sort(function (a, b) {
                return (
                  new Date(a.date + " " + a.start) -
                  new Date(b.date + " " + b.start)
                );
              })
              .map((d) => (
                <SwitchCase day={day} date={d.date} data={d} />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedule;
